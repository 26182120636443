@import "../base/colors";

/***
  title: kanjiBlob
  section: blob
  description: a blob of data related to a single kanji
  example: |
    <div class="container">
      <div class="row">
        <div class="blob col-xs-4 title">
          <div class="kanji">心</div>
          <div>
            <div class="romaji">shin</div>
            <div class="english">heart</div>
            <div class="hiragana">しん</div>
          </div>
        </div>
        <div class="row">
        <div class="blob col-xs-4">
          <div class="kanji"><a href="#">心</a></div>
          <div class="meta">
            <div class="romaji">shin</div>
            <div class="english">heart</div>
            <div class="hiragana">しん</div>
          </div>
        </div>
      </div>
    </div>
***/

.row.title {
  background-color: silver;
}

body {
  color: black;
}

.blob {
  display: flex;
  flex-basis: content;
  
  display: flex;
  &.kanji {
    height: 50px;
  }
  &.stroke-order{
    height: 100px;
  }
  

  .title {
    font-weight: bold;
    border: 0px;
  }

  .kanji {
    font-size: 30px;
  }
  .meta {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;

    .romaji, .characterNum, .english, .hiragana {
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;
      height:23px;
      vertical-align: middle;
    }
    .romaji {
      background-color: #c4e8c0;
      text-transform: capitalize;
    }
    .characterNum {
      background-color: yellow;
    }
    .english {
      font-size: 8px;
      background-color: $gray;
    }
    .hiragana {
      background-color: #c6c5ee;
    }
  }
}

.hidden {
  display: none;
}

a {
  color: $black;
  text-decoration: none;
}
