@import "./../base/_measures.scss";
.page {
  margin-top: $header-bar-height;
}

.content {
  width: $content-width;
}

.root {
  margin: auto;
  width: $content-width;
  height: 100vh;
}

.container {
  width: $content-width;
  height: 100vh;
}
.title {
  width: $content-width;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.sutra-container-kanji {
  display: flex;
  flex-direction: row-reverse;
  height: calc(100vh - 40px);
  overflow-x: scroll;
}

.sutra-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100vh;
  width: $content-width;
  overflow-y: scroll;
}

.sutra-body-kanji, .sutra-body {
  display: flex;
  height: calc(100vh - 40px);
}

.title-kanji {
  height: calc(100vh - 40px);
}

.sutra-body {
  flex-direction: row;
  flex-wrap: wrap;
  width: $content-width;
}

.sutra-body-kanji {
  flex-direction: column;
  flex-wrap: wrap-reverse;
  flex-grow: 1;
}