@import "../base/_colors.scss";
@import "../base/_measures.scss";

/***
  title: Header
  section: Module
  description:
  example: |
    <header>Some Text</header>
***/

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
// .header_spacer {
//   margin-top: $header-bar-height
// }
.logo-bar {
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $black;
  height: $header-bar-height;
  font-family: sans-serif;

  .logo {
    width: 100px;
    padding: 0px;
    margin: 0px;
  }
  .options {
    width: 100%;
  }
  .close-menu-button {
    text-align: right;
    width: 100px;
    font-size: 20px;
    padding: 7px;
    padding-right: 10px;

    a {
      font-size: 20px;
      color: red;

    }
  }

  &.expanded {
    height: $expanded-header-bar-height;
  }

  div {
    &.hidden {
      display: none;
    }

    height: 100%;
    display: inline-block;
    color: $white;
  }
  fieldset {
    border-radius: 5px;
    height: 177px;
  }
  legend {
    border-radius: 5px;

    padding: 5px;
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 20px;
  }

  label {
    display: block;
  }
  h4 {
    text-align: center;
    text-decoration: underline;
  }

  .col-xs-8 {
    min-width: 227px;
  }
  .col-xs-5 {
    min-width: 114px;
  }
  .logo-button {
    min-width: 112px;
    display: inline-block;
    text-decoration: none;
    font-size: 20px;
    padding: 7px;
    color: red;
    border-radius: 25%;
  }
  .logo-button.expanded:after {
    font-size: 14px;
    margin-left: 10px;
    position: relative;
    content: "\25B2";
  }
  .logo-button:not(.expanded):after {
    font-size: 14px;
    margin-left: 10px;
    position: relative;
    content: "\25BC";
  }
}
