@import "../base/_colors";

.splash {
  margin: auto;
  width: 80%;

  .logo-button {
    text-decoration: none;
    font-size: 20px;
    padding: 5px;
    color: red;
    font-weight: bold;
  }

  h1 {
    width: 100%;
    text-align: center;
  }
}


