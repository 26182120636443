@import "../base/colors";
/***
  title: Square buttons
  section: Buttons
  description: Very pretty square buttons
  example: |
    <a href="" class="btn btn-small">button</a>
    <a href="" class="btn btn-medium">button</a>
    <a href="" class="btn btn-large">button</a>
***/

.btn{
  display: inline-block;
  padding: .3em .6em;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $black;
}
.btn:hover{
  background-color: #38306E;
}
.btn-small{
  font-size: .8em;
}
.btn-medium{
  font-size: 1em;
}
.btn-large{
  font-size: 1.3em;
}


/***
  title: Round buttons
  section: Buttons
  description: Very pretty rounded buttons
  example: |
    <a href="" class="btn btn-small btn-round">button</a>
    <a href="" class="btn btn-medium btn-round">button</a>
    <a href="" class="btn btn-large btn-round">button</a>
***/

.btn-round{
  border-radius: 20px;
}

/***
  title: Links
  section: Buttons
  id: btn-link
  description: Very pretty rounded buttons
  example:
    <a href="" class="btn-link">button</a>
***/

.btn-link{
  background: none;
  color: darkslateblue;
}
.btn-link:hover{
  text-decoration: none;
}

/***
  title: Includes
  section: Includes
  description: Include example code from other items. You can refer to any attribute(set), like 'id'
  example: |
    <div class="btn-group">
        <include title="Links" section="Buttons">
        <include title="Links" section="Buttons">
        <include id="btn-link">
    </div>
***/
.btn-group .btn-link {
  background: green;
}
